import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import { generateClient } from 'aws-amplify/api';
import { listCovers, listUSERS } from '../../graphql/queries';
import { createCover, updateCover, deleteCover } from '../../graphql/mutations';
import del from "../../assets/img/del.png";
import edit from "../../assets/img/Edit.png";
import { getCurrentUser } from 'aws-amplify/auth';

export default function EditCover() {
  const [covers, setCovers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCoverImage, setNewCoverImage] = useState('');
  const [selectedCover, setSelectedCover] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const client = generateClient();

  const getUserId = async () => {
    try {
      const { userId } = await getCurrentUser();
      if (userId) {
        const listUsersResponse = await client.graphql({
          query: listUSERS,
        });
        const currentUser = listUsersResponse.data.listUSERS.items.find(user => user.logid === userId);
        setIsAdmin(currentUser.admin);
      }
    } catch (error) {
      console.error("Error fetching user ID or admin status:", error);
    }
  };

  useEffect(() => {
    const getCoverImage = async () => {
      try {
        const listCoversResponse = await client.graphql({
          query: listCovers,
        });
        setCovers(listCoversResponse.data.listCovers.items);
      } catch (error) {
        console.error("Error fetching covers:", error);
      }
    };

    getUserId();
    getCoverImage();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setNewCoverImage(reader.result); // Base64 string
      };
      reader.onerror = () => {
        console.error("Error reading file");
      };
      reader.readAsDataURL(file); // Convert to Base64
    }
  };

  const handleAddCover = async () => {
    try {
      const createResponse = await client.graphql({
        query: createCover,
        variables: { input: { image: newCoverImage } },
      });
      setCovers((prevCovers) => [...prevCovers, createResponse.data.createCover]);
      setNewCoverImage('');
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding cover:", error);
    }
  };

  const handleDeleteCover = async (coverId) => {
    if (!isAdmin) {
      alert("You do not have permission to delete this article.");
      return;
    }

    try {
      await client.graphql({
        query: deleteCover,
        variables: { input: { id: coverId } },
      });
      setCovers((prevCovers) => prevCovers.filter(cover => cover.id !== coverId));
    } catch (error) {
      console.error("Error deleting cover:", error);
    }
  };

  const handleEditCover = async () => {
    try {
      if (selectedCover && newCoverImage) {
        await client.graphql({
          query: updateCover,
          variables: { input: { id: selectedCover.id, image: newCoverImage } },
        });
        setCovers((prevCovers) => prevCovers.map(cover =>
          cover.id === selectedCover.id ? { ...cover, image: newCoverImage } : cover
        ));
        setNewCoverImage('');
        setSelectedCover(null);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating cover:", error);
    }
  };

  return (
    <main>
      <Header pageName="cover" isAdmin={isAdmin} />

      {/* Cover List */}
      <div className="p-4">
        {covers.map((cover) => (
          <div key={cover.id} className="relative mb-4">
            <img src={cover.image} alt="Cover" className="w-32 h-auto" />
            <button
              className="absolute top-2 right-2 bg-transparent border-none"
              onClick={() => {
                setSelectedCover(cover);
                setNewCoverImage('');
                setIsModalOpen(true);
              }}
            >
              <img src={edit} alt="Edit" className="w-6 h-6" />
            </button>
            <button
              className="absolute top-2 right-10 bg-transparent border-none"
              onClick={() => handleDeleteCover(cover.id)}
            >
              <img src={del} alt="Delete" className="w-6 h-6" />
            </button>
          </div>
        ))}
      </div>

      {/* Add Cover Button */}
      <button
        className="bg-black fixed bottom-10 right-10 hover:bg-gray-700 text-white text-5xl font-bold rounded-3xl w-14 h-14 text-center flex justify-center items-center"
        onClick={() => {
          setSelectedCover(null);
          setNewCoverImage('');
          setIsModalOpen(true);
        }}
      >
        <p className="mb-3">+</p>
      </button>

      {/* Add/Edit Cover Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md w-full max-w-lg">
            <h2 className="text-2xl font-bold mb-4">
              {selectedCover ? "Edit Cover" : "Add New Cover"}
            </h2>
            
            {/* File Input */}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full mb-4 p-2 border rounded"
            />

            {/* Image Preview */}
            {newCoverImage && (
              <div className="mb-4 flex justify-center">
                <img
                  src={newCoverImage}
                  alt="Selected Cover"
                  className="max-w-full max-h-48 object-contain border rounded"
                />
              </div>
            )}

            {/* Modal Buttons */}
            <div className="flex justify-end mt-4">
              <button
                type="button"
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700"
                onClick={selectedCover ? handleEditCover : handleAddCover}
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="ml-2 bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-700"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
