import React from 'react';
import cover from '../assets/img/cardCover.jpeg'; // Fallback image
import { Link } from 'react-router-dom';

export default function Card({ article }) {
  // Convert the ISO date string to a Date object
  const dateObj = new Date(article.createdAt);

  // Format the date to the desired format (e.g., "Jul 12, 2024")
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = dateObj.toLocaleDateString('en-US', options);

  // Check if images are available and set a fallback
  const imageSrc = article.images 

  // Encode the title to make it URL-friendly
  const encodedTitle = encodeURIComponent(article.Titles);

  return (
    <Link 
      to={`/article/${encodedTitle}/${article.id}`}  
      state={{ articleID: article.id }} 
      className="block" // Ensures the entire card is clickable
    >
      <div className="flex flex-col w-full p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
        <img 

          src={imageSrc} 
          alt="Cover" 
          className="h-64 w-full rounded-lg object-cover transition-transform duration-300 transform hover:scale-105" 
        />
        <div className="pt-4">
          <p className="text-base text-gray-400 font-normal">{article.rubrique}</p>
          <p className="text-[21px] font-semibold leading-tight mt-2">{article.Titles}</p>
          <p className="text-base text-gray-400 font-normal mt-2">{formattedDate}</p>
        </div>
      </div>
    </Link>
  );
}
