import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Card from '../Components/Card';
import Header from '../Components/Header';
import { generateClient } from 'aws-amplify/api';
import { listCovers } from '../graphql/queries';
import { Link } from 'react-router-dom';
import { listUSERS } from '../graphql/queries'; // Importer la requête pour lister les utilisateurs
import { getCurrentUser } from 'aws-amplify/auth';

export default function Home() {
  const TopTabArray = ["Actualité", "nouveauté", "Portrait", "Chronique", "Agenda"];
  const [articles, setArticles] = useState([]);
  const [covers, setCovers] = useState([]);
  const [search, setSearch] = useState('');
  const [userID, setUserID] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const client = generateClient();
 
  const pageName = "Actualité";

  const getArticles = async () => {
    try {
      const listArticlesResponse = await client.graphql({
        query: listArticles,
      });
      setArticles(listArticlesResponse.data.listArticles.items);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }
  };

  const getCoverImage = async () => {
    try {
      const listCoversResponse = await client.graphql({
        query: listCovers,
      });
      setCovers(listCoversResponse.data.listCovers.items);
    } catch (error) {
      console.error("Error fetching covers:", error);
    }
  };
  const fetchUserId = async () => {
    try {
      const { userId } = await getCurrentUser(); // Récupérer l'ID de l'utilisateur connecté
      setUserID(userId);

      // Requête pour vérifier si l'utilisateur est un admin
      const listUsersResponse = await client.graphql({ query: listUSERS });
      const currentUser = listUsersResponse.data.listUSERS.items.find(user => user.logid === userId);
      if (currentUser) {
        setIsAdmin(true); 
 
        // Définir le statut admin en fonction de l'utilisateur récupéré
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de l'ID de l'utilisateur ou du statut admin :", error);
    }
  };

  useEffect(() => {
    getArticles();
    getCoverImage();
    fetchUserId();
  }, []);

  // Filter articles based on the search input
  const filteredArticles = articles.filter(article => 
    article.Titles.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <main className='flex flex-col min-h-screen'>
      <Header pageName={pageName} search={search} setSearch={setSearch} />

      {/* Admin Button */}
      {isAdmin && (
        <div className="flex justify-center mt-8">
          <Link 
            to='/editorMasterRaceLinkPro'
            className="w-40 bg-gray-800 text-white font-semibold py-2 px-6 rounded-lg shadow-md hover:bg-gray-700 transition duration-300 text-center">
            page Admin 
          </Link>
        </div>
      )}

      {/* Carousel */}
      <div className='flex-grow'>
        <Carousel
          autoPlay
          infiniteLoop
          centerMode
          interval={3000}
          showStatus={false}
          showThumbs={false}
          showArrows={true}
          className='carousel mt-[-25px]'
        >
          {covers.map((image) => (
            <div key={image.id} className='carousel-item'>
              <img src={image.image} className='w-full h-auto bg-slate-950'/>
            </div>
          ))}
        </Carousel>

        <h2 className='p-2 text-3xl font-bold mt-8 w-full'>Recent</h2>
        <div className='p-2 w-full max-w-2xl mt-1 mx-auto'>
          {filteredArticles.map((article, index) => (
            <Card key={index} article={article} />
          ))}
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-black text-white py-4 w-full text-center mt-auto">
        <div className="container mx-auto text-center">
          <p className="text-lg font-semibold">lalecturejecontribue</p>
          <p className="text-sm mt-1">© 2024 lalecturejecontribue. All rights reserved.</p>
          <div className="mt-4">
            <Link to="/Apropos" className="text-white mx-2 hover:underline">À propos</Link>
            <Link to="/contact" className="text-white mx-2 hover:underline">Contact</Link>
          </div>
          <div className="mt-4 flex justify-center space-x-4">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-white">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-white">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-white">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-white">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </footer>
    </main>
  );
}

const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticlesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Titles
        images
        userID
        rubrique
        createdAt
        updatedAt
        __typename
        Paragraphs {
          items {
            createdAt
            id
            order
            text
            title
            Images {
              items {
                id
                descriptions
                link
                positions
                __typename
              }
            }
            __typename
          }
        }
      }
      nextToken
      __typename
    }
  }
`;
